import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CheckInService } from './check-in.service';
import { LogService } from './log.service.service';

interface DispenserMessage {
  code: string;
  description: string;
  cardId?: string;
  error?: boolean
  payload: any,
}

@Injectable({
  providedIn: 'root'
})
export class CardDispenserService {

  private static CMD_KEEP_ALIVE = 'keep-alive';
  private static CMD_CARD_POSITION = "card-position";
  private static CMD_CARD_LISTENER_INIT = 'card-listener-init';
  private static CMD_CARD_ISSUING_START = 'card-issuing-start';
  private static CMD_CARD_ISSUING_FINISH = 'card-issuing-finish';
  private static CMD_CARD_RETURNING_FINISH = 'card-returning-finish';
  private static CMD_STANDBY_TO_INPUT = 'stand-by-to-input';
  private static CMD_STANDBY_TO_STACK = 'stand-by-to-stack';
  private static CMD_STANDBY_TO_ERROR_BOX = 'stand-by-to-error-box';

  private static INTENT_INVALID_CARD_REMOVED_CHECK = 'intent-invalid-card-removed-check';

  private static CODE_POSITION_NONE = "0x0099";
  private static CODE_POSITION_BEZEL = "0x0010";
  private static CODE_POSITION_STANDBY = "0x0018"

  private static CODE_OK = "0x0000";
  private static CODE_PONG = "0x0001";
  private static CODE_ERROR = "0x9999";
  private static CODE_NOT_DEFINED = "0x2001";
  private static CODE_NOT_AVAILABLE = "0x2002";
  private static CODE_FRAME_ERROR = "0x2003";
  private static CODE_CARD_JAMMED = "0x2004";
  private static CODE_NO_CARD = "0x2005";
  private static CODE_CARD_INSIDE = "0x2006";
  private static CODE_BUSY = "0x2007";
  private static CODE_RTC_ERROR = "0x2008";
  private static CODE_TWO_CARDS = "0x2009";
  private static CODE_PORT_NOT_OPEN = "0x2010";
  private static CODE_EMPTY_STACK = "0x0021";

  private static CODE_CARD_READ_ERROR = "0x9009";
  private static CODE_CARD_RETURNING_READ_SUCCESS = "0x7000";
  private static CODE_CARD_RETURNING_FINISHED = "0x7001";
  private static CODE_CARD_RETURNING_READ_ERROR = "0x7009";
  private static CODE_CARD_ISSUING_READ_SUCCESS = "0x8000";
  private static CODE_CARD_ISSUING_FINISHED = "0x8001";
  private static CODE_CARD_ISSUING_READ_ERROR = "0x8009";

  private keepAliveInterval: number = 30000; //30s
  private port: any = null;
  private isHostConnected: boolean = false;

  logMessage = new BehaviorSubject<string>('');
  hasInvalidCard = new BehaviorSubject<boolean>(false);

  constructor(
    private logService: LogService,
    private checkInService: CheckInService
  ) { }

  init() {
    if (!this.hasMessageListener()) {
      return;
    }

    this.initConnection();
    this.initMessageListener();
    this.initKeepAlivePinger();
    this.sendCommand(CardDispenserService.CMD_CARD_LISTENER_INIT);
  }

  getIsActive() {
    return this.port ? true : false;
  }

  startCardIssuing(payload: any) {
    this.sendCommand(CardDispenserService.CMD_CARD_ISSUING_START, payload);
  }

  processInvalidCard() {
    this.sendCommand(CardDispenserService.CMD_STANDBY_TO_ERROR_BOX);
    this.logErrorBox({});
    setTimeout(() => {
      this.sendCommand(CardDispenserService.CMD_CARD_LISTENER_INIT);
      this.hasInvalidCard.next(false);
    }, 1000);
  }

  processInvalidCardRemoved() {
    this.sendCommand(CardDispenserService.CMD_CARD_LISTENER_INIT);
    this.hasInvalidCard.next(false);
  }

  checkIsInvalidCardRemoved() {
    this.sendCommand(CardDispenserService.CMD_CARD_POSITION, { intent: CardDispenserService.INTENT_INVALID_CARD_REMOVED_CHECK });
  }

  sendCommand(command: string, payload: { [key: string]: any } | null = null) {
    try {
      if (!this.hasMessageListener()) {
        this.log('Discarding command, as instance does not have native host: ' + command);
        return;
      }

      if (this.isHostConnected) {
        this.log('Discarding command, as native host is not connected: ' + command);
        return;
      }

      this.port.postMessage({ command: command, payload: payload });
    } catch (error) {
      let message = {
        title: 'Error executing discpenser command',
        command: command,
        payload: payload,
        error: error
      }
      this.log(JSON.stringify(message));
      this.checkInService.isCheckingIn.next(false);
      this.checkInService.isCheckingOut.next(false);
      this.checkInService.displayError(444);
    }

  }

  private hasMessageListener() {
    return !!environment.cardDispenserExtensionId;
  }

  private initConnection() {
    this.log(`Connecting to extension messaging host ID=${environment.cardDispenserExtensionId}`);
    this.port = chrome.runtime.connect(environment.cardDispenserExtensionId);
  }

  private initMessageListener() {
    this.log("Initializing host listeners...");
    this.port.onMessage.addListener((msg: DispenserMessage) => this.processMessage(msg));
    this.port.onConnect?.addListener(() => {
      this.isHostConnected = true;
      this.log('Card Dispenser native host CONNECTED');
    });
    this.port.onDisconnect?.addListener(() => {
      this.isHostConnected = false;
      this.log('Card Dispenser native host DISCONNECTED');
    });
  }

  private initKeepAlivePinger() {
    this.log("Initializing keep alive pinger...");
    setInterval(() => {
      this.sendCommand(CardDispenserService.CMD_KEEP_ALIVE);
    }, this.keepAliveInterval);
  }

  private processMessage(message: DispenserMessage) {
    this.logDispenserMessage(message);
    switch (message?.code) {
      case CardDispenserService.CODE_OK: break;
      case CardDispenserService.CODE_PONG: break;
      case CardDispenserService.CODE_CARD_INSIDE: break;
      case CardDispenserService.CODE_CARD_ISSUING_READ_SUCCESS: this.processCardIssuing(message); break;
      case CardDispenserService.CODE_CARD_RETURNING_READ_SUCCESS: this.processCardReturning(message); break;
      case CardDispenserService.CODE_CARD_RETURNING_READ_ERROR: this.processReturningReadError(message); break;
      case CardDispenserService.CODE_CARD_ISSUING_READ_ERROR: this.processIssuingReadError(message); break;
      case CardDispenserService.CODE_EMPTY_STACK: this.processEmptyStackError(message); break;
      case CardDispenserService.CODE_POSITION_NONE:
      case CardDispenserService.CODE_POSITION_BEZEL:
      case CardDispenserService.CODE_POSITION_STANDBY:
        this.processCardPosition(message); break;
      default: break;
    }
  }

  private processCardPosition(message: DispenserMessage) {
    if (message?.payload?.intent == CardDispenserService.INTENT_INVALID_CARD_REMOVED_CHECK && message?.code == CardDispenserService.CODE_POSITION_NONE) {
      this.processInvalidCardRemoved();
    }
  }

  private processCardIssuing(message: DispenserMessage) {
    if (message?.error) {
      this.log('Card issuing, there was an error: ' + message.description);
      return;
    }

    if (message?.cardId && message.payload?.authCode) {
      this.log('Granting access to card ID: ' + message.cardId);
      this.checkInService.createAttendeeAccessCardByAuthCode(message.payload.authCode, message.cardId, { expand: "appointment.company,appointment.hosts" }).subscribe(
        (attendee) => {
          this.checkInService.isCheckingIn.next(false);
          this.checkInService.redirectToSuccess(attendee);
          this.sendCommand(CardDispenserService.CMD_CARD_ISSUING_FINISH);
        },
        (ex) => {
          this.checkInService.isCheckingIn.next(false);
          this.checkInService.displayError(ex.status);
          this.sendCommand(CardDispenserService.CMD_STANDBY_TO_STACK);
          setTimeout(() => {
            this.sendCommand(CardDispenserService.CMD_CARD_LISTENER_INIT);
          }, 1000);
        });
    }
  }

  private processCardReturning(message: DispenserMessage) {
    if (message?.error) {
      this.log('Process Card Returning, there was an error: ' + message.description);
    }

    this.checkInService.isCheckingOut.next(true);

    if (message?.cardId) {
      this.log('Revoking entrance for card ID: ' + message.cardId);
      this.checkInService.deleteAttendeeAccessCardByCardNumber(message.cardId, { expand: "appointment.company,appointment.hosts" }).subscribe(
        (attendee) => {
          this.checkInService.isCheckingOut.next(false);
          this.checkInService.redirectToSuccess(attendee, 'check-out');
          this.sendCommand(CardDispenserService.CMD_CARD_RETURNING_FINISH);
        },
        (ex) => {
          this.checkInService.isCheckingOut.next(false);
          this.sendCommand(CardDispenserService.CMD_STANDBY_TO_INPUT);
          this.hasInvalidCard.next(true);
        });
    } else {
      this.checkInService.isCheckingOut.next(false);
    }
  }

  private processReturningReadError(message: DispenserMessage) {
    this.log('There was an error upon card read on returning: ' + message.description);
    this.sendCommand(CardDispenserService.CMD_STANDBY_TO_INPUT);
    this.hasInvalidCard.next(true);
  }

  private processIssuingReadError(message: DispenserMessage) {
    if (message?.error) {
      this.log('There was an error upon card read on issuing: ' + message.description);
      this.sendCommand(CardDispenserService.CMD_STANDBY_TO_ERROR_BOX);
      this.logErrorBox(message);
    }

    setTimeout(() => {
      this.startCardIssuing(message.payload);
    }, 5000);
  }

  private processEmptyStackError(message: DispenserMessage) {
    if (message?.error) {
      this.logErrorBox(message);
      this.log('Dispenser card tray is empty: ' + message.description);

      this.checkInService.checkAuthCode(message.payload?.authCode).then((attendee) => {
        this.checkInService.isCheckingIn.next(false);
        this.checkInService.redirectToSuccess(attendee);
        this.sendCommand(CardDispenserService.CMD_CARD_LISTENER_INIT);
      }).catch((ex) => {
        this.checkInService.isCheckingIn.next(false);
        this.checkInService.displayError(ex.status);
      });
    }
  }

  private logDispenserMessage(message: DispenserMessage) {
    this.log(`${message?.error ? 'ERROR ' : ''}Code: ${message.code}.\n${message.description}`);
  }

  private log(message: string) {
    this.logMessage.next(message);
    if (this.isRemoteLoggingEnabled()  && !message.includes('Alive signal')) {
      this.logService.logMessage({ message: message }).subscribe();
    }
  }

  private logErrorBox(payload: any) {
    this.checkInService.createAccessCardError(payload).subscribe();
  }

  private isRemoteLoggingEnabled() {
    return environment.hasRemoteLogging || false;
  }
}
